export default {

    /** Auth **/
    async forgotPassword(email, is_customer = false) {
        return window.axios.post('/api/forgot-password', {email, is_customer})
    },
    async resetPassword(params) {
        return window.axios.post('/api/reset-password', params)
    }

}
